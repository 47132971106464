<template>
  <!-- Table Container Card -->
  <b-card no-body class="mb-0 box-shadow-0">
    <!-- Table Top -->
    <b-row class="mb-1">
      <!-- Per Page -->
      <b-col cols="12" md="3" class="d-flex align-items-center">
        <v-select
          v-model="params.numberOfItemsPerPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mr-50"
        />
        <label>{{ $tc('pagination.entries', params.numberOfItemsPerPage) }}</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4" class="mb-md-0 mb-2">
        <enum-select
          id="organizationStatus"
          v-model="params.status"
          enum-class="OrganizationStatus"
          :label="$t('organization.status')"
        />
      </b-col>
      <b-col cols="12" md="4" class="mb-md-0 mb-2">
        <app-input
          id="organizationUser-workrole"
          v-model="params.workrole"
          :label="$t('user.workrole')"
          debounce="500"
        />
      </b-col>
      <!-- FullName -->
      <b-col cols="12" md="4">
        <app-input
          id="organizationUser-legalName"
          v-model="params.legalName"
          :label="$t('organization.name')"
          debounce="500"
        />
      </b-col>
    </b-row>

    <app-data-table
      ref="refUserListTable"
      table-name="user-list-table"
      :items="organizationUsers"
      :fields="tableColumns"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :per-page="params.numberOfItemsPerPage"
      :overlay="loadOrganizationUsers"
      :bottom-table="{ totalCount: totalOrganizationUsers }"
      @table-select-per-page="params.numberOfItemsPerPage = $event"
      @pagination="params.page = $event"
    >
      <!-- Column: Id -->
      <template #cell(organizationId)="data">
        <span> #{{ data.item.id }} </span>
      </template>

      <!-- Column: User -->
      <template #cell(legalName)="data">
        <b-link class="font-weight-bold d-block text-nowrap">
          {{ data.item.name }}
        </b-link>
      </template>

      <!-- Column: Role -->
      <template #cell(workrole)="data">
        <div class="text-nowrap">
          <font-awesome-icon icon="user" :class="`mr-50 text-${resolveRoleVariant(data.item.workrole)}`" />
          {{ data.item.workrole }}
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize">
          {{ data.item.status }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-link
          :to="{ name: 'user-organization-details', params: { organization_id: data.item.organization.id, organizationUser_id: data.item.id } }"
        >
          <feather-icon icon="EyeIcon" />
        </b-link>
      </template>
    </app-data-table>

    <app-form-wizard-footer class="mt-3" previous next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />
  </b-card>
</template>

<script>
import { fetchUserOrganizationsRequest } from '@/request/globalApi/requests/userOrganizationRequest'
import UIHelper from '@/helpers/ui'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'
import AppDataTable from '@/components/AppDataTable.vue'

export default {
  name: 'Organization',

  components: {
    AppFormWizardFooter,
    AppDataTable,
  },
  mixins: [UIHelper],
  data() {
    return {
      tableColumns: [
        { key: 'organizationId', label: this.$t('common.id') },
        { key: 'organization.legalName', label: this.$t('organization.name') },
        { key: 'workrole' },
        { key: 'status' },
        { key: 'actions', thClass: 'max-width-actions', tdClass: 'max-width-actions' },
      ],
      loadOrganizationUsers: false,
      organizationUsers: null,
      totalOrganizationUsers: 0,
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
        // SORTING
        legalNameOrder: null,
        workroleOrder: null,
        statusOrder: null,
        // SEARCH
        legalName: null,
        workrole: null,
        status: null,
      },
      perPageOptions: [10, 25, 50, 100],
      sortBy: null,
      sortDesc: null,
    }
  },
  computed: {
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
        legalName: this.params.legalName,
        workrole: this.params.workrole,
        status: this.params.status,
        legalNameOrder: this.params.legalNameOrder,
        workroleOrder: this.params.workroleOrder,
        statusOrder: this.params.statusOrder,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchOrganizationUsers()
  },
  methods: {
    APIFetchOrganizationUsers() {
      if (this.sortBy) {
        switch (this.sortBy) {
          case 'name':
            this.params.legalNameOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'workrole':
            this.params.workroleOrder = this.sortDesc ? 'asc' : 'desc'
            break
          case 'status':
            this.params.workroleStatus = this.sortDesc ? 'asc' : 'desc'
            break
          default:
            this.params.legalNameOrder = 'asc'
        }
      }
      const payload = {
        params: this.params,
      }
      this.loadOrganizationUsers = true
      fetchUserOrganizationsRequest(payload).then(response => {
        const { organizationUsers, totalItems } = response.data
        this.organizationUsers = organizationUsers
        this.totalOrganizationUsers = totalItems
      }).finally(() => {
        this.loadOrganizationUsers = false
      })
    },
    refetchData() {
      this.APIFetchOrganizationUsers()
      this.$refs.refUserListTable.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.actionCol {
  width: 14%;
}
.per-page-selector {
  width: 90px;
}
</style>
