<template>
  <b-sidebar id="sidebar-add-address" :visible="isAddNewAddressSidebarActive" no-header @change="val => $emit('update:is-add-new-address-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('common.address') }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <validation-observer ref="addAddressRules" #default="{ handleSubmit }">
        <b-form class="p-1" @submit.prevent="handleSubmit(onSubmit)">
          <!-- NAME -->
          <validation-provider #default="{ errors }" :name="$t('address.name')">
            <b-form-group :label="$t('address.name')" label-for="addressName">
              <b-form-input
                id="addressName"
                v-model="address.address.addressName"
                :placeholder="$t('address.name')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!-- ADDRESS -->
          <validation-provider #default="{ errors }" :name="$t('common.address')" class="validation-required" rules="required">
            <b-form-group :label="$t('common.address')" label-for="address">
              <b-form-input id="address" v-model="address.address.address" :placeholder="$t('common.address')" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- ADDRESS Complementary-->
          <b-form-group :label="$t('address.complementary_address')" label-for="complementaryAddress">
            <b-form-input id="complementaryAddress" v-model="address.address.complementaryAddress" :placeholder="$t('address.complementary_address')" />
          </b-form-group>

          <!-- Postalcode -->
          <validation-provider #default="{ errors }" :name="$t('address.postal_code')" class="validation-required" rules="required">
            <b-form-group :label="$t('address.postal_code')" label-for="postalCode">
              <b-form-input
                id="postalCode"
                v-model="address.address.postalCode"
                :placeholder="$t('address.postal_code')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- City -->
          <validation-provider #default="{ errors }" :name="$t('address.city')" class="validation-required" rules="required">
            <b-form-group :label="$t('address.city')" label-for="city">
              <b-form-input id="city" v-model="address.address.city" :placeholder="$t('address.city')" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <enum-select v-model="address.address.countryCode" :label="$t('common.country')" enum-class="CountryCode" text="text" />

          <!-- More info -->
          <b-form-group :label="$t('address.more_informations')" label-for="moreInformation">
            <b-form-textarea id="moreInformation" v-model="address.address.additionalInformations" :placeholder="$t('address.more_informations')" size="sm" />
          </b-form-group>

          <!-- Default address -->
          <b-form-group label-for="codeBuilding">
            <b-form-checkbox v-model="address.default">
              {{ $t('address.default') }}
            </b-form-checkbox>
          </b-form-group>
          <!-- FOOTER -->
          <div class="d-flex mt-2">
            <b-button class="mr-2" type="submit">
              <font-awesome-icon class="mr-50" icon="map-marked-alt" />
              {{ $route.query.action === 'add' ? $t('action.add') : $t('action.update') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="hide">
              <font-awesome-icon class="mr-50" icon="ban" />
              {{ $t('action.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { patchUserAddressRequest, postUserAddressRequest } from '@/request/globalApi/requests/userRequests'

export default {
  name: 'SidebarAddAddress',

  props: {
    isAddNewAddressSidebarActive: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      default: 0,
    },
    address: {
      type: Object,
      default: () => ({
        address: {
          addressName: '',
          address: '',
          complementaryAddress: '',
          postalCode: '',
          city: '',
          contactName: '',
          contactEmail: '',
          contactPhone: '',
          additionalInformations: '',
          buildingCode: '',
          countryCode: '',
        },
        default: false,
      }),
    },
  },

  methods: {
    onSubmit() {
      // ADD ADDRESS
      if (this.$route.query.action === 'add') {
        postUserAddressRequest(this.userId, this.address).then(() => {
          this.resetValidator()
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-address-sidebar-active', false)
        })
      } else {
        // EDIT ADDRESS
        patchUserAddressRequest(this.userId, this.address).then(() => {
          this.resetValidator()
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-address-sidebar-active', false)
        })
      }
    },
    validationForm() {
      this.$refs.addAddressRules.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.addAddressRules) {
          this.$refs.addAddressRules.reset()
        }
      })
    },
  },
}
</script>
