<template>
  <div>
    <validation-observer ref="accountPasswordRules" tag="form" @submit.prevent="validationForm">
      <div class="d-flex">
        <h4 class="mb-1">{{ $t('account.security.title') }}</h4>
      </div>

      <!-- Field: Old password -->
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider #default="{ errors }" :name="$t('account.security.password.old')" class="validation-required" rules="required">
            <b-form-group :label="$t('account.security.password.old')">
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid' : null"
                class="input-group-merge"
              >
                <b-form-input
                  id="oldPassword"
                  v-model="password.oldPassword"
                  :state="errors.length > 0 ? false : null"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Fields: New password -->
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider #default="{ errors }" :name="$t('account.security.password.new')" class="validation-required" rules="required">
            <b-form-group :label="$t('account.security.password.new')">
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid' : null"
                class="input-group-merge"
              >
                <b-form-input
                  id="newPassword"
                  v-model="password.newPassword"
                  :state="errors.length > 0 ? false : null"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="new-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <p>{{ $t('common.note') }} {{ $t('account.security.password.note') }}</p>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider #default="{ errors }" :name="$t('account.security.password.confirm')" class="validation-required" rules="required">
            <b-form-group :label="$t('account.security.password.confirm')">
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid' : null"
                class="input-group-merge"
              >
                <b-form-input
                  id="confirmPassword"
                  v-model="password.repeatNewPassword"
                  :state="errors.length > 0 ? false : null"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility()" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <app-form-wizard-footer class="mt-3" previous save next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')">
        <template #save-btn>
          <font-awesome-icon icon="paper-plane" />
          {{ $t('account.security.send_reinitialization_request') }}
        </template>
      </app-form-wizard-footer>

    </validation-observer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { patchChangePasswordRequest } from '@/request/globalApi/requests/userRequests'
import { extend } from 'vee-validate'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: () => 'not match',
})

export default {
  name: 'Password',
  components: {
    AppFormWizardFooter,
  },
  mixins: [togglePasswordVisibility],
  props: {
    userAccount: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      saveLoader: false,
      password: {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      },
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      return this.$refs.accountPasswordRules.validate().then(success => {
        if (success) {
          patchChangePasswordRequest(this.password)
        }
        return false
      })
    },
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
