<template>
  <section>
    <app-data-table
      ref="refPaymentMethodsListTable"
      table-name="payment-method"
      :items="paymentMethods"
      :fields="tableColumns"
      :per-page="params.numberOfItemsPerPage"
      :small="$store.getters['app/mdAndDown']"
      :overlay="loadPaymentMethods"
      :top-table="{}"
      :actions-table="{ showDelete: true }"
      :bottom-table="{ totalCount: totalItems }"
      manual-pagination
      @table-select-per-page="params.numberOfItemsPerPage = $event"
      @delete="confirmDeleteAlert($event.item.id, $event.item.default)"
      @pagination="params.page = $event"
    >
      <template #actions>
        <div class="d-flex justify-content-end">
          <b-button @click="isAddNewPaymentMethodSidebarActive = true">
            <font-awesome-icon icon="plus" class="mr-50" />
            {{ $t('account.payment_method.action.add_payment_method') }}
          </b-button>
        </div>
      </template>

      <!-- Column: id -->
      <template #cell(id)="data">
        <span> #{{ data.value }} </span>
      </template>

      <!-- Column: lastCharacters -->
      <template #cell(lastCharacters)="data">
        <div class="text-nowrap">**** {{ data.item.lastCharacters }}</div>
      </template>

      <!-- Column: Exp month -->
      <template #cell(expirationMonth)="data">
        {{
          !!data.item.expirationMonth
            ? $moment()
              .month(data.item.expirationMonth - 1)
              .format('MMMM')
            : ''
        }}
      </template>

      <!-- Column: Default -->
      <template #cell(default)="data">
        <b-form-checkbox
          v-model="data.item.default"
          :disabled="paymentMethods.length <= 1"
          name="check-button"
          switch
          inline
          @change="confirmEditAlert(data.item, data.item.default)"
        />
      </template>
    </app-data-table>

    <app-form-wizard-footer class="mt-3" previous next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />

    <!--SIDEBAR-->
    <SidebarAddPaymentMethod
      :payment-methods="paymentMethods"
      :is-add-new-payment-method-sidebar-active.sync="isAddNewPaymentMethodSidebarActive"
      @refetch-data="refetchData"
    />
  </section>
</template>

<script>
import SidebarAddPaymentMethod from '@/views/account/sidebar/SidebarAddPaymentMethod.vue'
import {
  deletePaymentMethodRequest,
  editPaymentMethodRequest,
  fetchPaymentMethodsRequest,
} from '@/request/globalApi/requests/paymentMethodRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'
import AppDataTable from '@/components/AppDataTable.vue'

export default {
  name: 'PaymentMethod',

  components: {
    SidebarAddPaymentMethod,
    AppFormWizardFooter,
    AppDataTable,
  },
  data() {
    return {
      tableColumns: [
        { key: 'id' },
        { key: 'brand' },
        { key: 'lastCharacters' },
        { key: 'expirationMonth' },
        { key: 'expirationYear' },
        { key: 'type' },
        { key: 'default' },
        { key: 'actions' },
      ],
      paymentMethods: [],
      totalItems: 0,
      loadPaymentMethods: false,
      isAddNewPaymentMethodSidebarActive: false,
      params: {
        // PAGE
        numberOfItemsPerPage: 10,
        page: 1,
      },
    }
  },
  computed: {
    allFilters() {
      return {
        numberOfItemsPerPage: this.params.numberOfItemsPerPage,
        page: this.params.page,
      }
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchPaymentMethods()
  },
  methods: {
    APIFetchPaymentMethods() {
      const payload = {
        params: this.params,
      }
      this.loadPaymentMethods = true
      fetchPaymentMethodsRequest(payload).then(response => {
        const { paymentMethods, totalItems } = response.data
        this.paymentMethods = paymentMethods
        this.totalItems = totalItems
      }).finally(() => {
        this.$refs.refPaymentMethodsListTable.refresh()
        this.loadPaymentMethods = false
      })
    },
    confirmEditAlert(paymentMethod, paymentDefault) {
      this.$swal({
        title: this.$t('alert.delete_edition.title'),
        text: this.$t('alert.delete_edition.message'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.delete_edition.approve'),
        cancelButtonText: this.$t('alert.delete_edition.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          editPaymentMethodRequest(paymentMethod).then(() => {
            this.refetchData()
          })
        } else {
          paymentMethod.default = !paymentDefault
        }
      })
    },
    confirmDeleteAlert(paymentMethodId) {
      this.$swal({
        title: this.$t('alert.delete_confirmation.title'),
        text: this.$t('alert.delete_confirmation.message'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.delete_confirmation.approve'),
        cancelButtonText: this.$t('alert.delete_confirmation.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          deletePaymentMethodRequest(paymentMethodId).then(() => {
            this.refetchData()
          })
        }
      })
    },
    refetchData() {
      this.APIFetchPaymentMethods()
    },
  },
}
</script>
