<template>
  <section>
    <b-row class="mb-2">
      <b-col class="d-flex justify-content-end" cols="12">
        <b-button class="mx-1" @click=";(isAddNewAddressSidebarActive = true), changeTypeForm('add')">
          {{ $t('action.add') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="address in userAddresses.addresses" :key="address.id" cols="12" lg="3" md="6">
        <b-card :class="[address.default ? 'border-primary' : '']" class="pb-0" no-body>
          <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
            <h5>{{ address.address.addressName }}</h5>
            <b-badge v-show="address.default" variant="light-primary">
              {{ $t('address.default') }}
            </b-badge>
          </b-card-header>
          <b-card-body>
            <ul class="list-unstyled">
              <li>
                {{ address.address.address }}
              </li>
              <li>
                {{ address.address.complementaryAddress }}
              </li>
              <li>
                {{ address.address.postalCode }}
              </li>
              <li>
                {{ address.address.city }}
              </li>
              <li>
                {{ address.address.countryCode }}
              </li>
            </ul>
            <div>
              <b-button variant="flat-primary" @click=";(isAddNewAddressSidebarActive = true), changeTypeForm('edit'), (addressSelected = address)">
                <font-awesome-icon class="mr-50" icon="edit" />
                {{ $t('action.edit') }}
              </b-button>
              <b-button v-show="!address.default" variant="flat-primary" @click="confirmAlert(address.address.id)">
                <font-awesome-icon class="mx-50" icon="trash" />
                {{ $t('action.delete') }}
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <app-form-wizard-footer class="mt-3" previous @click:previous="$emit('previous-tab')" />

    <sidebar-add-address
      :address="addressSelected"
      :is-add-new-address-sidebar-active.sync="isAddNewAddressSidebarActive"
      :user-id="userAccount.id"
      @refetch-data="refetchData"
    />
  </section>
</template>

<script>
import SidebarAddAddress from '@/views/account/sidebar/SidebarAddAddress.vue'
import { deleteUserAddressRequest, fetchUserAddressesRequest } from '@/request/globalApi/requests/userRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  name: 'AddressBook',
  components: {
    SidebarAddAddress,
    AppFormWizardFooter,
  },
  props: {
    userAccount: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const $blankAddress = {
      address: {
        addressName: '',
        address: '',
        complementaryAddress: '',
        postalCode: '',
        city: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        additionalInformations: '',
        buildingCode: '',
        countryCode: '',
      },
      default: false,
    }

    return {
      isAddNewAddressSidebarActive: false,
      addressSelected: $blankAddress,
      userAddresses: [],
      blankAddress: $blankAddress,
    }
  },
  mounted() {
    this.fetchUserAddresses()
  },
  methods: {
    fetchUserAddresses() {
      fetchUserAddressesRequest({}, this.userAccount.id).then(response => {
        this.userAddresses = response.data
      })
    },
    changeTypeForm(type) {
      this.$route.query.action = type
      this.addressSelected = this.blankAddress
    },
    confirmAlert(addressId) {
      this.$swal({
        title: this.$t('alert.delete_confirmation.are_you_sure'),
        text: this.$t('alert.delete_confirmation.message'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.delete_confirmation.approve'),
        cancelButtonText: this.$t('alert.delete_confirmation.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-dark ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          deleteUserAddressRequest(this.userAccount.id, addressId)
            .then(() => {
              this.refetchData()
            })
            .catch(() => {
              this.toaster(this.$t('alert.delete.error'), 'danger', 'AlertTriangleIcon')
            })
        }
      })
    },
    refetchData() {
      this.fetchUserAddresses()
    },
  },
}
</script>
